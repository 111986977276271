body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form {
  padding-top: 40px;
  padding-bottom: 50px;
  max-width: 350px;
  margin: 2em auto;
  text-align: center;
}

.form-title {
  font-family: "Ubuntu", "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #a04000;
  /*color: #34495E;*/
}

.sub-title {
  font-family: "Ubuntu", "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: grey;
}

.center {
  position: absolute;
  top: 40%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.overlay {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.186);
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

.key {
  font-weight: bold;
  margin-right: 5px;
  color: #ef6134;
  margin-bottom: -4px;
}

.pair {
  color: #0c3e97;
  margin-bottom: -4px;
}

.border {
  border: 1px solid #2c3e50;
  padding: 15px 10px;
}

.MuiTablePagination-selectLabel {
  margin-top: 15px;
}
/*pre{*/
/*  height: 150px;*/
/*  width: 100%;*/
/*  display: inline-block;*/
/*}*/

.MuiTablePagination-displayedRows {
  margin-top: 15px;
}

.errorMessage {
  font-family: "Rajdhani", "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 26px;
}

.tbCell {
  font-family: "Ubuntu", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-size: 14px;
}

.tblFont {
  font-family: "Ubuntu", "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-size: 13px;
}

.tbHead {
  font-family: "Ubuntu", "Helvetica Neue", sans-serif;
  font-weight: bold;
  font-size: 14px;
}

@media (min-width: 768px) {
  .form-title {
    font-family: "Ubuntu", "Helvetica Neue", sans-serif;
    font-weight: bold;
    font-size: 28px;
    color: #a04000;
    /*color: #34495E;*/
  }

  .sub-title {
    font-family: "Ubuntu", "Helvetica Neue", sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: grey;
  }

  .tblFont {
    font-family: "Ubuntu", "Helvetica Neue", sans-serif;
    font-weight: normal;
    font-size: 14px;
  }

  .mui-table {
    margin-top: -13px;
  }
}

.mui-table {
  margin-top: -8px;
}

/*
 *  STYLE 2
 */

/*::-webkit-scrollbar-track {*/
/*    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
/*    border-radius: 0px;*/
/*    background-color: #F5F5F5 !important;*/
/*}*/

/*::-webkit-scrollbar {*/
/*    width: 8px;*/
/*    background-color: #F5F5F5 !important;*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*    border-radius: 10px;*/
/*    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);*/
/*    background-color: #D62929 !important;*/
/*}*/

/*
 *  STYLE 6
 */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #f90;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

.MuiButton-contained {
  text-transform: capitalize !important;
}
@media print {
  .no-print {
    display: none;
  }
}
