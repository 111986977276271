/*.form-title {*/
/*    font-size: 3rem;*/
/*    font-weight: 700;*/
/*    text-align: center;*/
/*    color: #333;*/
/*}*/

a {
  text-decoration: none;
  color: white;
}

.text-success {
  color: #5d6d7e !important;
}

.text-danger {
  color: #dc3545;
}

.text-warning {
  color: #ffc107;
}

.text-info {
  color: #17a2b8;
}
