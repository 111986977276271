.dropdown-item:hover {
  background-color: #e83d13;
  color: white;
}

ul {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.navbar-brand {
  cursor: grab;
  font-family: "Rajdhani", "Helvetica Neue", sans-serif;
  /*font-family: 'Lato', sans-serif;*/
  font-weight: bold;
  font-size: 24px;
  color: white;
  margin-left: 10px;
}

.nav-link:hover {
  color: #96fa64 !important;
}

.dropdown-item.active {
  background-color: #bc14c1;
  color: white;
}

.dropdown-item {
  background-color: #1a5276;
  color: white;
}

.menu-icon {
  color: #96fa64;
}

.menu-icon:hover {
  color: yellow;
}
.navbar-toggler-icon{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-toggler-icon:hover{
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='orange' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
